.content-section {
  position: absolute;
  width: 100%;
  max-width: 880px;
  height: auto;
  top: 380px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  background-color: white;
  z-index: 150;
  transition: all 0.4s ease-in-out;

  .content-title-section {
    h1 {
      font-size: 50px;
      margin: 15px 15px 0px 15px;
    }
    h3 {
      margin: -10px 0 0 0;
      letter-spacing: 5px;
    }
  }

  .content-section-horizontal-scroll {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: left;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
    .page-subsection-item {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      justify-content: space-between;
      width: 520px;
      height: auto;
      background: white;
      border-bottom: solid rgb(200, 200, 200) 1px;
      margin: 10px;
      padding: 10px 10px 0px 10px;
      a {
        color: black;
        text-decoration: none;
        text-decoration-color: none;
      }
      .page-subsection-item-title {
        display: block;
        width: 100%;
        min-height: 70px;
        height: auto;
        text-align: left;
        .title-subtitle-box {
          display: block;
          width: 75%;
          height: auto;
          float: left;
          h2 {
            margin: 5px;
          }
          span {
            font-weight: bold;
            margin: 5px;
          }
        }
        .title-subject-box {
          display: block;
          float: left;
          .title-subject-badge {
            margin: 15px auto;
            padding: 5px 7px 5px 7px;
            border-radius: 5px;
            text-align: center;
            color: white;
          }
          .experience-badge {
            background: rgb(80, 200, 120);
          }
          .education-badge {
            background: rgb(116, 193, 207);
          }
        }
      }
      .page-subsection-item-content {
        position: relative;
        height: auto; 
        padding: 10px 0px 10px 0px;
        text-align: left;
        margin-bottom: auto;
        flex: 0 0 auto; 
        p {
          width: 95%;
          margin: 5px;
        }
      }
      .page-subsection-item-footer {
        display: block;
        width: 100%;
        height: auto;
        padding: 15px 0px 15px 0px;
        text-align: left;
        align-self: flex-end;
        .page-subsection-item-footer-icon {
          display: inline-block;
          max-width: 80px;
          padding: 0px;
          text-align: center;
          svg {
            display: inline-block;
            margin: 0 auto;
          }
          span {
            display: inline-block;
            width: 100%;
            font-size: 14px;
          }
        }
      }
    }
  }
}
