@keyframes particle-float-loop {
  0% {
    transform: translate(0px, 0px);
  }

  12% {
    transform: translate(-2px, 0px);
  }

  25% {
    transform: translate(-3px, 0px);
  }

  37% {
    transform: translate(0px, -2px);
  }

  50% {
    transform: translate(3px, -3px);
  }

  62% {
    transform: translate(2px, 0px);
  }

  75% {
    transform: translate(0px, -3px);
  }

  87% {
    transform: translate(0px, -2px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes ball-fly-left {
  0% {
    transform: translate(0px, -60px);
  }

  60% {
    transform: translate(-177px, -60px);
  }

  75% {
    transform: translate(-166px, -60px);
  }

  84% {
    transform: translate(-174px, -60px);
  }

  92% {
    transform: translate(-167px, -60px);
  }

  100% {
    transform: translate(-170px, -60px);
  }
}

@keyframes ball-fly-right-up {
  0% {
    transform: translate(0px, 0px);
  }

  60% {
    transform: translate(107px, -147px);
  }

  75% {
    transform: translate(96px, -136px);
  }

  84% {
    transform: translate(104px, -144px);
  }

  92% {
    transform: translate(97px, -137px);
  }

  100% {
    transform: translate(100px, -140px);
  }
}

@keyframes ball-fly-up {
  0% {
    transform: translate(-25px, 0px);
  }

  60% {
    transform: translate(-25px, -182px);
  }

  75% {
    transform: translate(-25px, -171px);
  }

  84% {
    transform: translate(-25px, -179px);
  }

  92% {
    transform: translate(-25px, -172px);
  }

  100% {
    transform: translate(-25px, -175px);
  }
}

@keyframes ball-fly-left-up {
  0% {
    transform: translate(0px, 0px);
  }

  60% {
    transform: translate(-107px, -147px);
  }

  75% {
    transform: translate(-96px, -136px);
  }

  84% {
    transform: translate(-104px, -144px);
  }

  92% {
    transform: translate(-97px, -137px);
  }

  100% {
    transform: translate(-100px, -140px);
  }
}

@keyframes ball-fly-right {
  0% {
    transform: translate(0px, -60px);
  }

  60% {
    transform: translate(177px, -60px);
  }

  75% {
    transform: translate(166px, -60px);
  }

  84% {
    transform: translate(174px, -60px);
  }

  92% {
    transform: translate(167px, -60px);
  }

  100% {
    transform: translate(170px, -60px);
  }
}

@keyframes appear-fly-up {
  from {
    opacity: 0;
    transform: translateY(300px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fly-up-like-a-ship {
  0% {
    opacity: 0;
    transform: translateY(200px);
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
  }

  30% {
    opacity: 1;
    transform: translateY(200px);
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
  }

  75% {
    opacity: 1;
    transform: translateY(0px);
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  }
}

@keyframes fly-out-left-like-a-ship {
  0% {
    opacity: 1;
    transform: translateX(0px);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  }

  30% {
    opacity: 1;
    transform: translateX(0px);
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
  }

  100% {
    opacity: 0;
    transform: translateX(-300px);
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.75);
  }
}

@keyframes shrink-pop {
  0% {
    transform: scale(1);
  }

  45% {
    transform: scale(.7);
  }

  60% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(.85);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes opacity-reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}